import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addReply } from "../../actions/articleActions";

class ReplyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { participant } = this.props.auth;
    const { articleId, discussionId } = this.props;

    const newReply = {
      text: this.state.text,
      name: participant.givenName,
      avatar: participant.avatar,
    };

    this.props.addReply(articleId, discussionId, newReply);
    this.setState({ text: "" });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;
    return (
      <div
        className="modal fade"
        id="replyModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form className="discussion-form" onSubmit={this.onSubmit}>
                <textarea
                  className="discussion-area"
                  name="text"
                  value={this.state.text}
                  onChange={this.onChange}
                  placeholder="Write your reply here"
                />
                {errors.text && <p>{errors.text}</p>}
                <div className="mt-4">
                  <div className="btn-group mt-4" role="group">
                    <button type="submit" className="btn btn-success">
                      Post Reply
                    </button>
                  </div>
                  <div className="btn-group float-right mt-4" role="group">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReplyForm.propTypes = {
  addReply: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  articleId: PropTypes.string.isRequired,
  discussionId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { addReply })(ReplyForm);
