import React, { Component } from "react";
import PropTypes from "prop-types";
import DiscussionItem from "./DiscussionItem";

class DiscussionFeed extends Component {
  render() {
    const { discussions, articleId } = this.props;

    return (
      <span>
        {discussions.map((discussion) => (
          <DiscussionItem
            key={discussion.id}
            discussion={discussion}
            articleId={articleId}
          />
        ))}
      </span>
    );
  }
}

DiscussionFeed.propTypes = {
  discussions: PropTypes.array.isRequired,
  articleId: PropTypes.string.isRequired,
};

export default DiscussionFeed;
