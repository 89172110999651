import {
  ADD_ARTICLE,
  ARTICLE_LOADING,
  GET_ARTICLES,
  DELETE_ARTICLE,
  GET_ARTICLE,
} from "../actions/types";

const initialState = {
  articles: [],
  article: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ARTICLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ARTICLES:
      return {
        ...state,
        articles: action.payload.articles,
        loading: false,
      };
    case GET_ARTICLE:
      return {
        ...state,
        article: action.payload.article,
        loading: false,
      };
    case ADD_ARTICLE:
      return {
        ...state,
        articles: [action.payload, ...state.articles],
      };
    case DELETE_ARTICLE:
      return {
        ...state,
        articles: state.articles.filter(
          (article) => article.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
