import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { deleteDiscussion } from "../../actions/articleActions";
import ReplyForm from "./ReplyForm";
import ReplyFeed from "./ReplyFeed";

class DiscussionItem extends Component {
  onDeleteClick(articleId, discussionId) {
    this.props.deleteDiscussion(articleId, discussionId);
  }

  render() {
    const { discussion, articleId, auth } = this.props;

    return (
      <div className="discussion-box-wrapper">
        <div className="discussion-box mb-4">
          <img
            src={discussion.avatar}
            className="discussioner-image"
            alt="discussioner_image"
          />
          <div className="discussion-content">
            <div className="discussioner-head">
              <span className="discussioner-name">{discussion.name}</span>
              <span className="discussion-date">
                <i className="far fa-clock ml-3"></i>{" "}
                <Moment fromNow>{discussion.date}</Moment>
              </span>
            </div>
            <div className="discussion-body mb-4 mt-2">
              <span className="discussion">{discussion.text}</span>
            </div>
            <div className="discussion-footer">
              <span className="discussion-likes">
                {discussion.likes.length}
                <Link to="#" className="discussion-action active">
                  <i className="far fa-heart ml-2"></i> Like
                </Link>
              </span>
              <span className="discussion-reply">
                {discussion.replies.length}{" "}
                <Link
                  to="#"
                  data-toggle="modal"
                  data-target="#replyModal"
                  className="discussion-action active ml-2"
                >
                  <i className="text-success fas fa-reply"></i> Reply
                </Link>
                <ReplyForm articleId={articleId} discussionId={discussion.id} />
              </span>
              {discussion.participant === auth.participant.id ? (
                <Link
                  to="#"
                  onClick={this.onDeleteClick.bind(
                    this,
                    articleId,
                    discussion.id
                  )}
                  className="discussion-action active"
                >
                  <i className="float-right text-danger fas fa-trash-alt"></i>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
        <ReplyFeed
          articleId={articleId}
          discussionId={discussion.id}
          replies={discussion.replies}
        />
      </div>
    );
  }
}

DiscussionItem.propTypes = {
  deleteDiscussion: PropTypes.func.isRequired,
  discussion: PropTypes.object.isRequired,
  articleId: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteDiscussion })(DiscussionItem);
