import React, { Component } from "react";
import PropTypes from "prop-types";
import ArticleItem from "./ArticleItem";

class ArticleFeed extends Component {
  render() {
    const { articles } = this.props;

    return (
      <div className="row">
        {articles.map((article) => (
          <ArticleItem key={article.id} article={article} />
        ))}
      </div>
    );
  }
}

ArticleFeed.propTypes = {
  articles: PropTypes.array.isRequired,
};

export default ArticleFeed;
