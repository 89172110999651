import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TextFieldGroup from "../common/TextFieldGroup";
import { addArticle } from "../../actions/articleActions";
import classnames from "classnames";

class ArticleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      image: { name: "" },
      text: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const newArticle = {
      title: this.state.title,
      image: this.state.image,
      text: this.state.text,
    };

    this.props.addArticle(newArticle);
    this.setState({ title: "", text: "", image: { name: "" } });
  }

  onChange(e) {
    if (e.target.files) {
      this.setState({
        [e.target.name]: e.target.files[0],
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <span>
        <div className="center mb-2">
          <button
            data-toggle="modal"
            data-target="#createArticleForm"
            className="btn btn-primary center-block"
          >
            Create New Article
          </button>
        </div>

        <div
          className="modal fade"
          id="createArticleForm"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">New Article</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.onSubmit}>
                <div className="modal-body">
                  <div className="form-group">
                    <TextFieldGroup
                      placeholder="* Article title"
                      name="title"
                      value={this.state.title}
                      onChange={this.onChange}
                      error={errors.title}
                      info="Provide captivating title for your article"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="articleImage">Article Image</label>
                    <br />
                    <input
                      name="image"
                      type="file"
                      id="articleImage"
                      onChange={this.onChange}
                    />
                    <small className="form-text text-muted">
                      Choose a good picture to go with your article.
                    </small>
                  </div>
                  <div className="form-group">
                    <textarea
                      placeholder="Start writing your article..."
                      name="text"
                      value={this.state.text}
                      onChange={this.onChange}
                      error={errors.text}
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.text,
                      })}
                      style={{ height: "300px" }}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Publish
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </span>
    );
  }
}

ArticleForm.propTypes = {
  addArticle: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { addArticle })(ArticleForm);
