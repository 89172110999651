import React, { Component } from "react";
import PropTypes from "prop-types";
import ReplyItem from "./ReplyItem";

class ReplyFeed extends Component {
  render() {
    const { replies, articleId, discussionId } = this.props;

    return (
      <div className="nested-discussions">
        {replies.map((reply) => (
          <ReplyItem
            key={reply.id}
            reply={reply}
            articleId={articleId}
            discussionId={discussionId}
          />
        ))}
      </div>
    );
  }
}

ReplyFeed.propTypes = {
  replies: PropTypes.array.isRequired,
  articleId: PropTypes.string.isRequired,
  discussionId: PropTypes.string.isRequired,
};

export default ReplyFeed;
