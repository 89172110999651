import axios from "axios";
import {
  ADD_ARTICLE,
  GET_ERRORS,
  ARTICLE_LOADING,
  GET_ARTICLES,
  DELETE_ARTICLE,
  GET_ARTICLE,
  CLEAR_ERRORS,
} from "./types";

const apiUrl = process.env.REACT_APP_API_URL;

// Get articles
export const getArticles = () => (dispatch) => {
  dispatch(setArticleLoading());
  axios
    .get(`${apiUrl}/api/articles`)
    .then((res) =>
      dispatch({
        type: GET_ARTICLES,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ARTICLES,
        payload: null,
      });
    });
};

// Get article by ID
export const getArticle = (id) => (dispatch) => {
  dispatch(setArticleLoading());
  axios
    .get(`${apiUrl}/api/articles/${id}`)
    .then((res) =>
      dispatch({
        type: GET_ARTICLE,
        payload: res.data,
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ARTICLE,
        payload: null,
      });
    });
};

// Add article
export const addArticle = (articleData) => (dispatch) => {
  let articlePayload = new FormData();
  let upload = articleData.image;

  articlePayload.append("title", articleData.title);
  articlePayload.append("text", articleData.text);
  articlePayload.append("image", upload);

  dispatch(clearErrors());
  axios
    .post(`${apiUrl}/api/articles`, articlePayload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) =>
      dispatch({
        type: ADD_ARTICLE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add discussion
export const addDiscussion = (articleId, discussionData) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .post(`${apiUrl}/api/articles/discussion/${articleId}`, discussionData)
    .then((res) =>
      dispatch({
        type: GET_ARTICLE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add reply
export const addReply = (articleId, discussionId, replyData) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .post(
      `${apiUrl}/api/articles/reply/${articleId}/${discussionId}`,
      replyData
    )
    .then((res) =>
      dispatch({
        type: GET_ARTICLE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete reply
export const deleteReply = (articleId, discussionId, replyId) => (dispatch) => {
  dispatch(clearErrors());
  axios
    .delete(
      `${apiUrl}/api/articles/reply/${articleId}/${discussionId}/${replyId}`
    )
    .then((res) =>
      dispatch({
        type: GET_ARTICLE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete discussion
export const deleteDiscussion = (articleId, discussionId) => (dispatch) => {
  axios
    .delete(`${apiUrl}/api/articles/discussion/${articleId}/${discussionId}`)
    .then((res) =>
      dispatch({
        type: GET_ARTICLE,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Add clap
export const addClap = (id) => (dispatch) => {
  axios
    .post(`${apiUrl}/api/articles/clap/${id}`)
    .then((res) => dispatch(getArticles()))
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Remove clap
export const removeClap = (id) => (dispatch) => {
  axios
    .post(`${apiUrl}/api/articles/unclap/${id}`)
    .then((res) => dispatch(getArticles()))
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Delete article
export const deleteArticle = (id) => (dispatch) => {
  axios
    .delete(`${apiUrl}/api/articles/${id}`)
    .then((res) =>
      dispatch({
        type: DELETE_ARTICLE,
        payload: id,
      })
    )
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Set loading state
export const setArticleLoading = () => {
  return {
    type: ARTICLE_LOADING,
  };
};

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
