import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addDiscussion } from "../../actions/articleActions";

class DiscussionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { participant } = this.props.auth;
    const { articleId } = this.props;

    const newDiscussion = {
      text: this.state.text,
      name: participant.givenName,
      avatar: participant.avatar,
    };

    this.props.addDiscussion(articleId, newDiscussion);
    this.setState({ text: "" });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { discussionCount } = this.props;
    const { errors } = this.state;
    return (
      <div className="row">
        <div className="col-12 mt-2">
          <h2>{discussionCount} Discussions</h2>
          <form className="discussion-form" onSubmit={this.onSubmit}>
            <textarea
              className="discussion-area"
              name="text"
              value={this.state.text}
              onChange={this.onChange}
              placeholder="Write your discussion here"
            />
            {errors.text && <p>{errors.text}</p>}
            <button type="submit" className="btn discussion-btn">
              Post Discussion
            </button>
          </form>
        </div>
      </div>
    );
  }
}

DiscussionForm.propTypes = {
  addDiscussion: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  articleId: PropTypes.string.isRequired,
  discussionCount: PropTypes.number,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { addDiscussion })(DiscussionForm);
