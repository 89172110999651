import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { deleteReply } from "../../actions/articleActions";

class ReplyItem extends Component {
  onDeleteClick(articleId, discussionId, replyId) {
    this.props.deleteReply(articleId, discussionId, replyId);
  }

  render() {
    const { reply, discussionId, articleId, auth } = this.props;

    return (
      <div className="discussion-box-wrapper">
        <div className="discussion-box mb-4">
          <img
            src={reply.avatar}
            className="discussioner-image"
            alt="discussioner_image"
          />
          <div className="discussion-content">
            <div className="discussioner-head">
              <span className="discussioner-name">{reply.name}</span>
              <span className="discussion-date">
                <i className="far fa-clock ml-3"></i>{" "}
                <Moment fromNow>{reply.date}</Moment>
              </span>
            </div>
            <div className="discussion-body mb-4 mt-2">
              <span className="discussion">{reply.text}</span>
            </div>
            <div className="discussion-footer">
              <span className="discussion-likes">
                {reply.likes.length}
                <Link to="#" className="discussion-action active">
                  <i className="far fa-heart ml-2"></i>
                </Link>
              </span>
              {reply.participant === auth.participant.id ? (
                <Link
                  to="#"
                  onClick={this.onDeleteClick.bind(
                    this,
                    articleId,
                    discussionId,
                    reply.id
                  )}
                  className="discussion-action active"
                >
                  <i className="float-right text-danger fas fa-trash-alt"></i>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReplyItem.propTypes = {
  deleteReply: PropTypes.func.isRequired,
  reply: PropTypes.object.isRequired,
  articleId: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteReply })(ReplyItem);
