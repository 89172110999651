import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Moment from "react-moment";
import {
  deleteArticle,
  addClap,
  removeClap,
} from "../../actions/articleActions";

class ArticleItem extends Component {
  onDeleteClick(id) {
    this.props.deleteArticle(id);
  }

  onClapClick(id, claps) {
    if (this.findParticipantClaps(claps)) {
      this.props.removeClap(id);
    } else {
      this.props.addClap(id);
    }
  }

  findParticipantClaps(claps) {
    const { auth } = this.props;

    if (
      claps.filter((clap) => clap.participantId === auth.participant.id)
        .length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { article, auth, showActions } = this.props;

    return (
      <div className="col-sm-4 col-md-4">
        <div className="article-sm">
          <div className="article-sm-img-content">
            <img
              src={
                article.image
                  ? article.image
                  : "https://cdn.pixabay.com/photo/2015/12/04/14/05/code-1076536_1280.jpg"
              }
              alt=""
              className="img-responsive"
            />
            <span className="article-sm-title">
              <b>{article.title}</b>
            </span>
          </div>
          <div className="content">
            <div className="author">
              By <b>{article.name}</b> | <Moment fromNow>{article.date}</Moment>
              {article.participantId === auth.participant.id ? (
                <Link
                  to="#"
                  onClick={this.onDeleteClick.bind(this, article.id)}
                  className="discussion-action active"
                >
                  <i className="float-right text-danger fas fa-trash-alt"></i>
                </Link>
              ) : null}
            </div>
            <p>{article.text}</p>
            {showActions ? (
              <span>
                <Link to={`/article/${article.id}`} className="btn btn-warning">
                  Read more
                </Link>
                <button
                  onClick={this.onClapClick.bind(
                    this,
                    article.id,
                    article.claps
                  )}
                  className={classnames("float-right text-white btn", {
                    "btn-success": this.findParticipantClaps(article.claps),
                    "btn-info": !this.findParticipantClaps(article.claps),
                  })}
                >
                  {" "}
                  <i className="fal fa-sign-language fa-flip-horizontal"></i>{" "}
                  Claps{" "}
                  <span
                    className={classnames("badge", {
                      "badge-success": this.findParticipantClaps(article.claps),
                      "badge-light": !this.findParticipantClaps(article.claps),
                    })}
                  >
                    {article.claps.length}
                  </span>
                </button>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

ArticleItem.defaultProps = {
  showActions: true,
};

ArticleItem.propTypes = {
  deleteArticle: PropTypes.func.isRequired,
  addClap: PropTypes.func.isRequired,
  removeClap: PropTypes.func.isRequired,
  article: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { deleteArticle, addClap, removeClap })(
  ArticleItem
);
