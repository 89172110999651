import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import ArticleForm from "./ArticleForm";
import ArticleFeed from "./ArticleFeed";
import Spinner from "../common/Spinner";
import { getArticles } from "../../actions/articleActions";

class Articles extends Component {
  componentDidMount() {
    this.props.getArticles();
  }

  render() {
    const { articles, loading } = this.props.articles;
    let articleContent;

    if (articles === null || loading) {
      articleContent = <Spinner />;
    } else {
      articleContent = <ArticleFeed articles={articles} />;
    }

    return (
      <div>
        <ArticleForm />
        <div className="container">
          <div className="row">
            <div className="col-md-12">{articleContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

Articles.propTypes = {
  getArticles: PropTypes.func.isRequired,
  articles: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  articles: state.articles,
});

export default connect(mapStateToProps, { getArticles })(Articles);
