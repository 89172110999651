import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getArticle } from "../../actions/articleActions";
import PropTypes from "prop-types";
import Spinner from "../common/Spinner";
import SingleArticleItem from "./SingleArticleItem";
import DiscussionForm from "./DiscussionForm";
import DiscussionFeed from "./DiscussionFeed";

class Article extends Component {
  componentDidMount() {
    this.props.getArticle(this.props.match.params.id);
  }
  render() {
    const { article, loading } = this.props.articles;
    let articleContent;

    if (article === null || loading || Object.keys(article).length === 0) {
      articleContent = <Spinner />;
    } else {
      articleContent = (
        <div>
          <SingleArticleItem article={article} showActions={true} />
          <DiscussionForm
            articleId={article.id}
            discussionCount={article.discussions.length}
          />
          <DiscussionFeed
            articleId={article.id}
            discussions={article.discussions}
          />
        </div>
      );
    }
    return (
      <div className="article">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Link to="/articles" className="btn btn-light mb-3">
                Back to articles
              </Link>
              {articleContent}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Article.propTypes = {
  getArticle: PropTypes.func.isRequired,
  articles: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  articles: state.articles,
});
export default connect(mapStateToProps, { getArticle })(Article);
