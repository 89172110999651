import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_PARTICIPANT } from "./types";

const apiUrl = process.env.REACT_APP_API_URL;

// Register Participant
export const registerParticipant = (participantData, history) => (dispatch) => {
  axios
    .post(`${apiUrl}/api/participants/register`, participantData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - Get Participant Token
export const loginParticipant = (participantData) => (dispatch) => {
  axios
    .post(`${apiUrl}/api/participants/login`, participantData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      setAuthToken(token);
      // Decode token to get participant data
      const decoded = jwt_decode(token);
      // Set current participant
      dispatch(setCurrentParticipant(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set logged in participant
export const setCurrentParticipant = (decoded) => {
  return {
    type: SET_CURRENT_PARTICIPANT,
    payload: decoded,
  };
};

//Log participant out
export const logoutParticipant = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentParticipant({}));
};
